import { SelectProps } from '@mui/material';
import {
  IHourlyRatesResProperties,
  IUnallocatedTasksResProperties,
} from 'src/apis/resourcePlannerAPI';

export enum EColumnName {
  ALLOCATION_START_DATE = 'allocationStartDate',
  CHECK = '__check__',
  START_DATE = 'startDate',
}

export type TDate = Date | null | undefined;

export type THourlyRateDropdown = Partial<
  IUnallocatedTasksResProperties & {
    'aria-label': string;
    hourlyRates: IHourlyRatesResProperties[];
    id: string;
    isFetching: boolean;
    onChange: SelectProps['onChange'];
    onClose: () => void;
    onMouseOver: () => void;
    onOpen: () => void;
    open: boolean;
    value: string;
  }
>;

export type TEditableHourlyRateRow = Partial<IUnallocatedTasksResProperties>;
