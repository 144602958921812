import {
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material';
import { forwardRef } from 'react';

// MUI is using div[role="button"] for ListItemButton
export const ListItemButton = forwardRef<HTMLDivElement, MuiListItemButtonProps>(
  ({ sx, ...rest }, ref) => <MuiListItemButton ref={ref} sx={{ width: '100%', ...sx }} {...rest} />,
);
