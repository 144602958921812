import { IUnallocatedTasksResProperties } from 'src/apis/resourcePlannerAPI';

export enum ETaskTableField {
  allocationEndDate = 'allocationEndDate',
  allocationStartDate = 'allocationStartDate',
  budgetString = 'budgetString',
  endDate = 'endDate',
  error = 'error',
  hourlyRate = 'hourlyRate',
  hours = 'hours',
  name = 'name',
  startDate = 'startDate',
  wbsNo = 'wbsNo',
}

export interface ITaskTableRow extends IUnallocatedTasksResProperties {
  allocationStartDate: Date;
  allocationEndDate: Date;
  budgetString: string;
  error: string;
  hourlyRate: string;
  hours: string;
  hoursInt: number;
}
