import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IHourlyRatesResProperties } from 'src/apis/resourcePlannerAPI';
import { useCustomEditComponent } from 'src/screens/ResourcePlanner/hooks';
import { TEditableHourlyRateRow } from '../EditableComponents.types';
import { HourlyRateDropdown } from '../HourlyRateDropdown/HourlyRateDropdown';

interface IEditableHourlyRate extends GridRenderEditCellParams {
  hourlyRates: IHourlyRatesResProperties[];
  isFetching: boolean;
}

export const EditableHourlyRate = ({
  field,
  hourlyRates,
  id,
  isFetching,
  name,
  row: r,
  value,
}: IEditableHourlyRate) => {
  const apiRef = useGridApiContext();
  const { t } = useTranslation('assignFlow');
  const { handleChange } = useCustomEditComponent<string>({ field, id });

  const row = r as TEditableHourlyRateRow;

  useEffect(() => {
    apiRef.current.setEditCellValue({
      id,
      field: 'hourlyRate',
      value: row.suggestedHourlyRateId,
    });
  }, [apiRef, id, row.suggestedHourlyRateId]);

  return (
    <HourlyRateDropdown
      aria-label={t('EditableComponents.HourlyRateLabel', { name })}
      hourlyRates={hourlyRates}
      id={String(id)}
      isFetching={isFetching}
      isShownHourlyRateDropDown={row.isShownHourlyRateDropDown}
      onChange={(e) => handleChange(String(e.target.value))}
      suggestedHourlyRateAmount={row.suggestedHourlyRateAmount}
      suggestedHourlyRateId={row.suggestedHourlyRateId}
      value={value}
    />
  );
};
