export enum EAvailabilityRgb {
  Red = '231, 87, 87',
  Green = '98, 201, 175',
}

export enum EAvailabilityHex {
  Red100 = '#e75757',
  Red66 = '#ef9191',
  Red33 = '#f7c8c8',
  Green10 = '#f0faf7',
  Green20 = '#e0f4ef',
  Green30 = '#d0efe7',
  Green40 = '#c0e9df',
  Green50 = '#b0e3d6',
  Green60 = '#a1dfcf',
  Green70 = '#92dac7',
  Green80 = '#81d4bf',
  Green90 = '#72cfb7',
  Green100 = '#62c9af',
}

const getRoundedA = (a: number) => (a > 0 ? Math.floor(a * 10) / 10 : Math.ceil(a * 10) / 10);

const getCondition = (a: number) => {
  if (a < -1) {
    return '';
  }

  if (a === 1) {
    return 'Green100';
  }

  const roundedA = getRoundedA(a);

  if (a < 0) {
    switch (roundedA) {
      case -0.1:
        return 'Red66';
      case 0:
        return 'Red33';
      default:
        return 'Red100';
    }
  }

  if (a > 1) {
    switch (roundedA) {
      case 1.1:
        return 'Red66';
      case 1:
        return 'Red33';
      default:
        return 'Red100';
    }
  }

  return `Green${roundedA * 100 + 10}`;
};

export const getAvailabilityColor = (a: number) =>
  EAvailabilityHex[getCondition(a) as keyof typeof EAvailabilityHex];

export const getAvailabilityColorOpacity = (a: number) => {
  switch (getCondition(a)) {
    case 'Red100':
      return `rgba(${EAvailabilityRgb.Red}, 1.0)`;
    case 'Red66':
      return `rgba(${EAvailabilityRgb.Red}, 0.66)`;
    case 'Red33':
      return `rgba(${EAvailabilityRgb.Red}, 0.33)`;
    case 'Green100':
      return `rgba(${EAvailabilityRgb.Green}, 1.0)`;
    default:
      return `rgba(${EAvailabilityRgb.Green}, ${(getRoundedA(a) + 0.1).toFixed(1)})`;
  }
};
