import {
  DesktopDatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { forwardRef } from 'react';

export const DatePicker = forwardRef<HTMLDivElement, MuiDatePickerProps<Date>>(
  ({ ...rest }, ref) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker {...rest} ref={ref} />
    </LocalizationProvider>
  ),
);
