import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

export const useCustomEditComponent = <T>({
  field,
  id,
}: Pick<GridRenderEditCellParams, 'field' | 'id'>) => {
  const apiRef = useGridApiContext();

  const handleChange = (newValue: T) => {
    if (!apiRef.current.isRowSelected(id)) {
      apiRef.current.selectRows([id]);
    }
    apiRef.current.setEditCellValue({
      field,
      id,
      value: newValue,
    });
  };

  return { handleChange };
};
