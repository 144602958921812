import { ConfirmOptions } from 'material-ui-confirm';
import { IParent } from 'src/screens/ResourcePlanner/types/resourcePlanner';

export interface IAssignFlowValue {
  isWorkItem: boolean;
  name: string;
  resourceId: string;
  resourceSourceReferenceId: string;
  resourceType: string;
  workItemId: string;
  workItemSourceReferenceId: string;
  workItemType: string;
  confirmOptions?: ConfirmOptions;
  parentProject?: IParent;
  parentTask?: IParent;
}

export const INITIAL_ASSIGN_FLOW_STORE: IAssignFlowValue = {
  isWorkItem: false,
  name: '',
  resourceId: '',
  resourceSourceReferenceId: '',
  resourceType: '',
  workItemId: '',
  workItemSourceReferenceId: '',
  workItemType: '',
};
