import { Ref, useState } from 'react';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { IDropdown } from 'src/apis/dropdownAPI/get/getDropDownWithSearchAPI';
import { Autocomplete } from 'src/components/mui-components';
import { AutocompleteProps } from 'src/components/mui-components/Autocomplete/Autocomplete';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';

interface IAutocomplete extends Omit<AutocompleteProps<IDropdown>, 'options'> {
  filterId: string;
  ref?: Ref<HTMLDivElement>;
}

export const FilterAutocomplete = ({ filterId, ...rest }: IAutocomplete) => {
  const [open, setOpen] = useState(false);

  const contentUrl = `api/v2/filters/${filterId}/content`;
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { dropdownList, isFetching } = useGetDropdownWithSearchAPI({
    key: `filterInput${filterId}`,
    MSWKey: `FILTER_INPUT_${filterId}`,
    path: `/${contentUrl}?pageIdentifier=${pageIdentifier}`,
    searchText: '',
    enabled: open,
  });

  return (
    <Autocomplete
      {...rest}
      getOptionDisabled={() => isFetching}
      loading={isFetching}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      options={dropdownList}
    />
  );
};
