import {
  IEmployeeSearchResProperties,
  IUnallocatedEmployeesResProperties,
} from 'src/apis/resourcePlannerAPI';

export enum EEmployeeTableField {
  competences = 'competences',
  endDate = 'endDate',
  error = 'error',
  fullName = 'fullName',
  hourlyRate = 'hourlyRate',
  hours = 'hours',
  startDate = 'startDate',
}

export type TEmployee = IEmployeeSearchResProperties & IUnallocatedEmployeesResProperties;

export type TEmployeeTableRow = TEmployee & {
  endDate: Date;
  hourlyRate: string;
  hours: string;
  startDate: Date;
};
